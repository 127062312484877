/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import axios from "axios";
import Swal from "sweetalert2";

// core components
import { Enviroments } from "../../enviroments/enviroments";

// hooks
import useUpdateBalance from "../../hooks/useUpdateBalance";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Popover,
  PopoverBody
} from "reactstrap";

import LogRocket from "logrocket";

function AdminNavbar(props) {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const history = useHistory();
  const [userMe, setUserMe] = React.useState(null);
  const userId = localStorage.getItem("userId");
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const callbackUpdateBalance = newValue => {
    setUserMe(prev => ({
      ...prev,
      firstBalance: newValue
    }));
  };

  const openUpdateBalanceModal = useUpdateBalance(
    userId,
    callbackUpdateBalance
  );

  LogRocket.identify(localStorage.getItem("userId"), {
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email")
  });

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });

  async function handleSubmit(event) {
    try {
      const email = localStorage.getItem("email");
      await getUserByEmail(email);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: `Logado com sucesso ${localStorage.getItem("name")}`
      });
    } catch (err) {
      localStorage.clear();
      history.push("/auth/login");
      console.log(err);
    }
  }

  const getUserByEmail = async email => {
    try {
      const response = await axios.get(
        `${Enviroments.API_URL_MINES}users/email/${email}`
      );
      setUserMe(response.data);
      console.log(response.data, "teste");
    } catch (error) {
      if (error.response) {
        // Erro na resposta do servidor (ex.: usuário não encontrado)
        return { error: error.response.data.message };
      } else {
        // Outro tipo de erro (ex.: falha na conexão)
        return { error: "Erro ao conectar ao servidor" };
      }
    }
  };

  const calculateDailyEarnings = (balance, rate) => {
    const dailyRate = rate / 100; // Taxa diária
    return balance * dailyRate;
  };

  const generateEarningsTable = (balance, rate) => {
    const rows = [];
    const currentDate = new Date();
    for (let day = 0; day < 15; day++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + day);
      const dateString = date.toLocaleDateString("pt-BR");
      const dailyEarnings = calculateDailyEarnings(balance, rate).toFixed(2);
      const total = (parseFloat(balance) + parseFloat(dailyEarnings)).toFixed(
        2
      );
      rows.push(
        `<tr><td style="font-size: 12px;">${dateString}</td><td style="font-size: 12px;">R$ ${balance.toFixed(
          2
        )}</td><td style="font-size: 12px;">R$ ${dailyEarnings}</td><td style="font-size: 12px;">R$ ${total}</td></tr>`
      );
      balance = parseFloat(total); // Atualiza a banca para o próximo dia
    }
    return `<table border="1" style="width: 100%; margin-top: 12px;"><thead><tr><th style="font-size: 12px;">Dia</th><th style="font-size: 12px;">Banca Atual (R$)</th><th style="font-size: 12px;">Meta Diária (R$)</th><th style="font-size: 12px;">Total (R$)</th></tr></thead><tbody>${rows.join(
      ""
    )}</tbody></table>`;
  };

  const showDetailedEarnings = (balance, rate) => {
    const tableHtml = generateEarningsTable(balance, rate);
    Swal.fire({
      title: "Detalhamento dos Ganhos Diários",
      html: `
      <p>Com uma banca de R$ ${balance} e uma taxa diária de ${rate}%, os ganhos diários ao longo dos próximos 15 dias são:</p>
      ${tableHtml}
    `,
      icon: "info",
      confirmButtonText: "Ok",
      customClass: {
        popup: "swal2-dark",
        confirmButton: "swal2-dark",
        cancelButton: "swal2-dark"
      }
    });
  };

  const showInvestmentSuggestions = balance => {
    const conservativeRate = 5; // 5% diário
    const moderateRate = 10; // 10% diário
    const aggressiveRate = 20; // 20% diário

    Swal.fire({
      title: "Sugestões da I.A. do Teleco!",
      html: `
      <h3>Valor da sua Banca: <strong>R$ ${balance.toFixed(2)}</strong></h3>
      <p><strong>Conservador:</strong> Meta de 5% ao dia</p>
      <p>Valor sugerido: R$ ${(balance * (5 / 100)).toFixed(
        2
      )} <button class="btn btn-default" id="conservative-button">Detalhar</button></p>
      <p><strong>Moderado:</strong> Meta de 10% ao dia</p>
      <p>Valor sugerido: R$ ${(balance * (10 / 100)).toFixed(
        2
      )} <button class="btn btn-default" id="moderate-button">Detalhar</button></p>
      <p><strong>Agressivo:</strong> Meta de 20% ao dia</p>
      <p>Valor sugerido: R$ ${(balance * (20 / 100)).toFixed(
        2
      )} <button class="btn btn-default" id="aggressive-button">Detalhar</button></p>
    `,
      icon: "info",
      confirmButtonText: "Ok",
      customClass: {
        popup: "swal2-dark",
        confirmButton: "swal2-dark",
        cancelButton: "swal2-dark"
      },
      didOpen: () => {
        document
          .getElementById("conservative-button")
          .addEventListener("click", () =>
            showDetailedEarnings(balance, conservativeRate)
          );
        document
          .getElementById("moderate-button")
          .addEventListener("click", () =>
            showDetailedEarnings(balance, moderateRate)
          );
        document
          .getElementById("aggressive-button")
          .addEventListener("click", () =>
            showDetailedEarnings(balance, aggressiveRate)
          );
      }
    });
  };

  React.useEffect(() => {
    handleSubmit();
  }, []);

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  // this function handles the logout button
  const logout = () => {
    if (
      localStorage.getItem("apiToken") != null ||
      localStorage.getItem("user") != null
    ) {
      localStorage.clear();
      history.push("/auth/login");
      return;
    }
  };
  return (
    <>
      <Navbar
        className={classNames("navbar-absolute", color)}
        expand="lg"
        style={{ padding: 0 }}
      >
        <Container fluid>
          <div className="navbar-wrapper d-flex justify-content-start w-100">
            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
              {props.brandText}
            </NavbarBrand>
          </div>

          <div className="d-flex nav-balance">
            <Button
              color="link"
              id="updateBalanceButton"
              outline
              size="sm"
              style={{ height: 32, marginTop: 6 }}
              onMouseEnter={togglePopover}
              onMouseLeave={togglePopover}
              onClick={() => openUpdateBalanceModal()}
            >
              <span>R$ {Number(userMe?.firstBalance).toFixed(2)}</span>
            </Button>
            <div className={popoverOpen ? "" : "d-none"}>
              <Popover
                placement="top"
                isOpen={popoverOpen}
                target="updateBalanceButton"
                toggle={togglePopover}
              >
                <PopoverBody>Clique para atualizar a banca</PopoverBody>
              </Popover>
            </div>

            <Button
              color="link"
              outline
              size="sm"
              style={{ height: 32, marginTop: 6 }}
              onClick={() => showInvestmentSuggestions(userMe?.firstBalance)}
            >
              I.A.
            </Button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." src={require("assets/img/anime3.png")} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">Perfil</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      Configurações
                    </DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li">
                    <DropdownItem className="nav-item">
                      <Button onClick={logout}>
                        <span className="d-md-block">Sair</span>
                      </Button>
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
