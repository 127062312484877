/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Home from "views/Home.js";
import Login from "views/Login";
import Register from "views/Register";
import Signals from "views/Signals";
import FortuneTiger from "views/FortuneTiger";
import CashMania from "views/CashMania";
import StockMarket from "views/StockMarket";
import Aviator2 from "views/Aviator2";
import Aviator10 from "views/Aviator10";
import GoalsTracker from "views/GoalsTracker";
import Dashboard from "views/Dashboard";
import FortuneDragon from "views/FortuneDragon";
import AviatorCombined from "views/AviatorCombined";
import Admin from "views/Admin";

var routes = [
  {
    path: "/admin",
    name: "Admin",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Admin,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "I.A Teleco",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Home,
    layout: "/admin"
  },
  {
    path: "/dashboard-aviator",
    name: "Dashboard Aviator",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/goals-tracker",
    name: "Gestao Banca I.A",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: GoalsTracker,
    layout: "/admin"
  },
  {
    path: "/signals",
    name: "Sinais I.A.",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Signals,
    layout: "/admin"
  },
  {
    path: "/fortune-tiger",
    name: "Fortune Tiger I.A.",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: FortuneTiger,
    layout: "/admin"
  },
  {
    path: "/cash-mania",
    name: "CashMania I.A.",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: CashMania,
    layout: "/admin"
  },
  {
    path: "/fortune-dragon",
    name: "Fortune Dragon I.A.",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: FortuneDragon,
    layout: "/admin"
  },
  {
    path: "/stock-market",
    name: "Stock Market I.A.",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: StockMarket,
    layout: "/admin"
  },
  {
    path: "/aviator",
    name: "Aviator I.A",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Aviator2,
    layout: "/admin"
  },
  {
    path: "/aviator10x",
    name: "Aviator I.A",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Aviator10,
    layout: "/admin"
  },
  {
    path: "/aviator-hack",
    name: "Aviator I.A",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: AviatorCombined,
    layout: "/admin"
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Login,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/register/:role",
    name: "Register",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Register,
    layout: "/auth",
    redirect: true
  }
];
export default routes;
