import React from 'react';
import Aviator1 from './Aviator2';
import Aviator10 from './Aviator10';
import AviatorFrame from 'components/AviatorFrame/AviatorFrame';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import HistoryOddsPinksMin from 'components/HistoryOddsPinksMin/HistoryOddsPinksMin';

const AviatorMain = () => {
  return (
    <div className="content mt-4 pt-4 pl-2 pr-2">
      <Row className="d-flex justify-content-center align-items-center mx-auto">
        <Col xs={3}>
          <Link to="signals">
            <div className="d-flex justify-content-center align-items-center">
              <i aria-hidden="true" className="fas fa-arrow-left fs-xxl"></i>
              <span className="ml-2">voltar</span>
            </div>
          </Link>
        </Col>
        <Col xs={9}>
          <h3 className="title m-0 text-right">I.A. Teleco Aviator</h3>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <p className="category">Sinais: 
            <strong style={{ color: "#ba54f5", fontSize: 14}}>{" "} 1.50X</strong> a {" "}
            <strong style={{ color: "#ba54f5", fontSize: 14}}>2.00X</strong>
          </p>
          <Aviator1 />
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="category">Sinais: <strong style={{ color: "#ba54f5", fontSize: 14}}>10X</strong></p>
          <Aviator10 />
        </Col>
      </Row>

      <Row>
        <Col>
          <HistoryOddsPinksMin></HistoryOddsPinksMin>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col>
          <AviatorFrame src="https://br4bet.com/play/3230" /> 
        </Col>
      </Row>
    </div>
  );
};

export default AviatorMain;
