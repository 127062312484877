import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Label
} from "reactstrap";

import Swal from "sweetalert2";
import { Link } from "react-router-dom";

import fortunelogo from "assets/img/fortune-tiger.webp";

const FortuneTiger = () => {
  const [updates, setUpdates] = useState([]);
  const [hourValidSignal, setHourValidSignal] = useState();
  const [strategySignal, setStrategySignal] = useState();
  const [signalFim, setSignalFim] = useState();
  const [activeSignal, setActiveSignal] = useState(false);

  const notify = () => {
    Swal.fire({
      icon: "success",
      title: "Sinal Confirmado!!!",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
      toast: true,
      position: "top"
    });
  };

  useEffect(() => {
    if (activeSignal) {
      notify();
    }
  }, [activeSignal]);

  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const response = await axios.get(
          "https://api.telegram.org/bot7465722968:AAENdJgAuubnwj219HsB6e72-BwpoGUblH4/getUpdates?offset=-1"
        );
        setUpdates(response.data.result);

        const message = response.data.result[0].channel_post.text;
        let horaValidade = "";
        let quantidadesTentativas = "";

        if (message.includes("Confirmada")) {
          const partesMensagem = message.split("\n");
          for (const parte of partesMensagem) {
            if (parte.includes("Válido até:")) {
              horaValidade = parte.replace("Válido até:", "").trim();
            } else if (parte.includes("Normal") || parte.includes("Turbo")) {
              quantidadesTentativas += parte + "\n";
            }
          }

          setHourValidSignal(`Valido até: ${horaValidade}`);
          setStrategySignal(quantidadesTentativas);
          setActiveSignal(true);
        } else {
          setActiveSignal(false);
          setHourValidSignal("Aguarde");
          setStrategySignal("Aguarde");
        }

        if (message.includes("Finalizado")) {
          let msg = message.split("Finalizado às:")[1];
          setSignalFim(`Ultima entrada: ${msg}`);
          setActiveSignal(false);
        } else {
          setSignalFim("");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUpdates();

    const intervalId = setInterval(fetchUpdates, 5000); // chamada a cada 5 segundos

    return () => {
      clearInterval(intervalId); // limpar o intervalo quando o componente for desmontado
    };
  }, []);

  return (
    <div className="content p-2 mt-5">
      <Row>
        <Col xs={2}>
          <Link to="signals" className="simple-text logo-mini ml-4 mt-4">
            <i aria-hidden="true" class="fas fa-arrow-left fs-xxl"></i>
            <span className="ml-2">voltar</span>
          </Link>
        </Col>
        <Col xs="3">
          <h5>{hourValidSignal}</h5>
        </Col>

        <Col xs="4">
          <h5>Estrategia: {strategySignal}</h5>
        </Col>

        <Col xs="3">
          <h5>{signalFim}</h5>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <iframe
            height="700"
            title="Hacking Aviator"
            style={{ borderRadius: 40 }}
            width={window.screen.width}
            name="aviator"
            className="col-md-12"
            src="https://go.aff.br4-partners.com/3faibgrt?utm_campaign=ia-teleco"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </div>
  );
};

export default FortuneTiger;
