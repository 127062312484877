import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";
import FixedButtonsFooter from "../components/FixedButtonsFooter/FixedButtonsFooter";

import iaTelecoLogo from "assets/img/ia-teleco.png";

const ResponsiveIframe = () => {
  return (
    <div className="ratio ratio-16x9">
      <iframe
        src="https://www.youtube.com/embed/lJBxBPogOPg?si=rP4Fly9tdtrw3gHO"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const Dashboard = () => {
  return (
    <div className="content ml-2 mr-2">
      <Row>
        <Col xs="12">
          <Card>
            <CardHeader></CardHeader>
            <CardBody className="all-icons">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <img src={iaTelecoLogo} width={100} alt="Logo Teleco"></img>

                <p className="mt-4 text-center">
                  Estamos na nossa primeira versão da I.A. do Teleco, espero que
                  consiga ir além dos seus próprios desafios dentro do jogo.
                  Este é o momento de voce aproveitar desta tecnologia para
                  mudar seus resultados no jogo.
                </p>

                <p className="text-center">
                  Quaisquer dúvidas não deixe de entrar em contato no suporte.
                </p>

                <div>
                  <p className="text-center">
                    Veja abaixo como utilizar a I.A. do Teleco.
                  </p>
                  <ResponsiveIframe />
                </div>

                <h4 className="mt-4">Links uteis abaixo:</h4>

                <a
                  href="https://pay.kirvano.com/5fdb00e0-5206-4bf1-9b53-aaadb5608357"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Acesso Vitalicio I.A. Teleco
                </a>
                <a
                  href="https://pay.kirvano.com/83e4c8ce-472b-4111-8188-c6c56a56a030"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Acesso Mensal I.A. Teleco
                </a>
                <a
                  href="https://go.aff.br4-partners.com/mldhslr5?utm_campaign=ia-teleco"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Cadastre-se aqui na BR4BET
                </a>
                <a
                  href="https://pay.kirvano.com/bbf2ebc0-aec4-45f9-8aff-de156ea98e5d"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Grupo VIP do Teleco - Lives
                </a>
                <a
                  href="https://t.me/telecosfree"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn btn-secondary"
                >
                  Grupo Free do Teleco - Lives
                </a>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <FixedButtonsFooter />
      </Row>
    </div>
  );
};

export default Dashboard;
