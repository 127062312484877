import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button, Label } from "reactstrap";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const StockMarket = () => {
  const [updates, setUpdates] = useState([]);
  const [hourValidSignal, setHourValidSignal] = useState();
  const [strategySignal, setStrategySignal] = useState();
  const [activeSignal, setActiveSignal] = useState(false);
  let [green, setGreen] = useState(false);
  let [red, setRed] = useState(false);

  const notify = (type, text) => {
    Swal.fire({
      icon: type,
      title: `DEEEEU ${text}`,
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
      toast: true,
      position: "top"
    });
  };

  useEffect(() => {
    if (green) {
      notify("success", "GREEN!!! ✅✅✅✅ ");
    }

    if (red) {
      notify("danger", "RED ❌ ");
    }
  }, [green, red]);

  useEffect(() => {
    const fetchUpdates = async () => {
      try {
        const response = await axios.get(
          "https://api.telegram.org/botYOUR_BOT_TOKEN/getUpdates?offset=-1"
        );
        setUpdates(response.data.result);

        const message = response.data.result[0].channel_post.text;
        let horaValidade = "";
        let quantidadesTentativas = "2 proteções";

        if (message.includes("ENTRADA CONFIRMADA")) {
          const partesMensagem = message.split("\n");
          for (const parte of partesMensagem) {
            if (parte.includes("Entrar após")) {
              horaValidade = parte;
            }
          }

          setHourValidSignal(`${horaValidade}`);
          setStrategySignal(quantidadesTentativas);
          setActiveSignal(true);
          setGreen(false);
          setRed(false);
        } else {
          setActiveSignal(false);
          setHourValidSignal("Aguarde");
          setStrategySignal("Aguarde");
        }

        if (message.includes("RED")) {
          setRed(true);
          setGreen(false);
        }

        if (message.includes("GREEN")) {
          setGreen(true);
          setRed(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchUpdates();

    const intervalId = setInterval(fetchUpdates, 2000); // chamada a cada 2 segundos

    return () => {
      clearInterval(intervalId); // limpar o intervalo quando o componente for desmontado
    };
  }, []);

  return (
    <div className="content p-4 mt-5">
      <Row>
        <Col xs={2}>
          <Link
            to="signals"
            className="simple-text logo-mini ml-4 mt-4"
            onClick={() => console.log("teste")}
          >
            <i aria-hidden="true" className="fas fa-arrow-left fs-xxl"></i>
            <span className="ml-2">voltar</span>
          </Link>
        </Col>
        <Col xs="5">
          <h5
            className="ms-3"
            style={{ border: "1px solid", borderRadius: 8, padding: 8 }}
          >
            Sinal: <strong>{hourValidSignal}</strong>
          </h5>
        </Col>

        <Col xs="5">
          <h5 style={{ border: "1px solid", borderRadius: 8, padding: 8 }}>
            Proteção: <strong>{strategySignal}</strong>
          </h5>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <iframe
            height="700"
            title="Hacking Aviator"
            style={{ borderRadius: 40 }}
            width={window.screen.width}
            name="aviator"
            className="col-md-12"
            src="https://minesbet.com/play/6034"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </div>
  );
};

export default StockMarket;
