import React from "react";
import FixedButtonsFooter from "../components/FixedButtonsFooter/FixedButtonsFooter";

import { Link } from "react-router-dom";
import {
  Row,
  Col,
} from "reactstrap";

const Signals = () => {
  return (
    <div className="content p-4 mt-5">
      <Row>
        <Col xs="4">
          <Link
            to="aviator-hack"
            className="simple-text logo-mini logo-aviator"
          ></Link>
        </Col>
        <Col xs="4">
          <Link
            to="fortune-tiger"
            className="simple-text logo-mini logo-fortune-tiger"
          ></Link>
        </Col>
        
        <Col xs="4">
          <Link
            to="dashboard-aviator"
            className="simple-text logo-mini logo-dashboard-aviator"
          ></Link>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs="4">
          <Link
            to="aviator-hack"
            className="simple-text logo-mini logo-aviator-10"
          ></Link>
        </Col>
        <Col xs="4">
          <Link
            to="cash-mania"
            className="simple-text logo-mini logo-cashmania"
          ></Link>
        </Col>
        <Col xs="4">
          <Link
            to="fortune-dragon"
            className="simple-text logo-mini logo-fortune-dragon"
          ></Link>
        </Col>
      </Row>
      <Row>
        <FixedButtonsFooter />
      </Row>
    </div>
  );
};

export default Signals;
