/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  Row,
  Col
} from "reactstrap";

import { Link } from "react-router-dom";

import HistoryOdds from "../components/HistoryOdds/HistoryOdds";
import HistoryOddsPinks from "../components/HistoryOddsPinks/HistoryOddsPinks";

function Dashboard(props) {
  return (
    <>
      <div className="content">
        <Row>
          <Link to="signals" className="simple-text logo-mini ml-4 mt-4 mb-2">
            <i aria-hidden="true" class="fas fa-arrow-left fs-xxl"></i>
            <span className="ml-2">voltar</span>
          </Link>
        </Row>
        <Row>
          <Col xs="12">
            <HistoryOdds props={{ betHouse: "MINES_BET" }}></HistoryOdds>
          </Col>
        </Row>
        <Row>
          <Col md="12" xs="12">
            <Card>
              <CardHeader>
                <h5 className="title">MINES_BET</h5>
                <p className="category">
                  Pode operar diretamente pelo nosso Dashboard!
                </p>
              </CardHeader>
              <CardBody className="all-icons">
                <iframe
                  height="700"
                  title="Hacking Aviator"
                  style={{ borderRadius: "12px", padding: 0 }}
                  width={window.screen.width}
                  name="aviator"
                  className="col-md-12"
                  src="https://go.aff.br4-partners.com/l59wgsqt"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
