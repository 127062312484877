import React from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Label
} from "reactstrap";

import { Link } from "react-router-dom";

const FixedButtonsFooter = () => {
  return (
    <div className="content">
      <Row>
        <div className="footer fixed-bottom w-100 d-flex p-4">
          <Card
            className="d-flex flex-row justify-content-between p-4"
            style={{ borderRadius: 60 }}
          >
            <Link to="dashboard" className="simple-text logo-mini">
              <Button
                className="p-0 m-0"
                color="secondary"
                style={{ height: 60, width: 60, borderRadius: "50%" }}
              >
                <i aria-hidden="true" class="fas fa-home"></i>
              </Button>
            </Link>

            <Link to="signals" className="simple-text logo-mini">
              <Button
                className="p-0 m-0"
                color="secondary"
                style={{ height: 60, width: 60, borderRadius: "50%" }}
              >
                <i aria-hidden="true" class="fas fa-gamepad"></i>
              </Button>
            </Link>

            <Link to="goals-tracker" className="simple-text logo-mini">
              <Button
                className="p-0 m-0"
                color="secondary"
                style={{ height: 60, width: 60, borderRadius: "50%" }}
              >
                <i aria-hidden="true" class="fa fa-table"></i>
              </Button>
            </Link>
            {localStorage.getItem("role") === "ROLE_ADMIN" && (
              <Link to="admin" className="simple-text logo-mini">
              <Button
                className="p-0 m-0"
                color="secondary"
                style={{ height: 60, width: 60, borderRadius: "50%" }}
              >
                <i aria-hidden="true" class="fa fa-user"></i>
              </Button>
            </Link>
            )}
          </Card>
        </div>
      </Row>
    </div>
  );
};

export default FixedButtonsFooter;
