import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button } from 'reactstrap';
import moment from 'moment';

import { Enviroments } from "../../enviroments/enviroments";

function HistoryOddsPinks() {
  const dateNow = moment(new Date()).format('YYYY-MM-DD');

  const [historyResultsAviator, setHistoryResultsAviator] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [numberVelas, setNumberVelas] = useState(10);
  const [updatedOdd, setUpdatedOdd] = useState(true);

  const getBadgeClassName = value => {
    if (value < 2) {
      return 'bg-info';
    } else if (value <= 9.99) {
      return 'bg-purple';
    } else if (value >= 10) {
      return 'bg-primary';
    }
  };

  const setResults = async () => {
    const res = await getHistoryResultsAviator();
    setHistoryResultsAviator(res);
  };

  useEffect(() => {
    if (updatedOdd) {
      setResults();
      setTimeout(() => {
        setUpdatedOdd(false);
      }, 10000);
    }
  }, [numberVelas, updatedOdd]);

  const getHistoryResultsAviator = async () => {
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apiToken')}`
        }
      };
      const response = await axios.get(
        `${Enviroments.API_URL_NODE}history-filter-odd?date=${dateNow}&numberVelas=${numberVelas}&betHouse=MINES_BET&filter=10`,
        headers
      );
      return response.data;
    } catch (error) {
      console.error(error);
    } finally {
      setHistoryLoading(false);
    }
  };

  return (
    <div className="history-odd pl-2 pr-2">
      {historyLoading? (
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <Row>
          <Col xs="12">
            <Row>
              <Col className="text-left" xs={8}>
                <h5 className="card-category">
                  Histórico de velas Rosas <br></br>clique em atualizar para as rosas
                </h5>
              </Col>
              <Col xs={4}>
                <Button size="sm" disabled={updatedOdd} onClick={() => setUpdatedOdd(true)}>Atualizar</Button>
              </Col>
            </Row>
            <Row>
              <div className="history-results row">
                {historyResultsAviator?.map((item, i) => (
                  <div className="vela-results" key={item + i}>
                    <span
                      key={item.hour}
                      className={`badge text-wrap ${getBadgeClassName(item.odd)}`}
                      style={{
                        color: "white",
                        backgroundColor:
                          getBadgeClassName(item.odd) === "bg-purple"
                            ? "#5603ad"
                            : ""
                      }}
                    >
                      {item.odd && item.odd}
                    </span>

                    <br />

                    <span style={{ fontSize: 11 }}>
                      {item.hour}
                    </span>
                  </div>
                ))}
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default HistoryOddsPinks;